<template>
  <footer class="site-footer">
    <div class="footer">
      <div class="footer-logo">
        <router-link to="/">
          <img width="140" height="40" :src="require('/src/assets/images/Logo.svg')" alt="logo">
        </router-link>
        <p class="logo-descript">Квіткове господарство<br>та садовий центр</p>
      </div>
      <nav>
        <div>
          <ul class="menu">
            <li><router-link to="/about">Про нас</router-link></li>
            <li><router-link to="/events">Події</router-link></li>
            <li><router-link to="#contacts">Контакти</router-link></li>
          </ul>
        </div>
        <div>
          <ul class="menu">
            <li><router-link to="/greening-of-offices">Озеленення офісів</router-link></li>
            <li><router-link to="/balcony-gardening">Балконне озеленення</router-link></li>
            <li><router-link to="/landscape-design">Ландшафтний дизайн</router-link></li>
          </ul>
        </div>
        <div>
          <ul class="menu">
            <li><router-link to="/mourning-floristics">Траурна флористика</router-link></li>
            <li><router-link to="/photo-zone">Фотозона</router-link></li>
            <li><router-link to="/kids-excursions">Дитячі екскурсії</router-link></li>
          </ul>
        </div>
        <div>
          <ul class="menu">
            <li><router-link to="/payment-and-delivery">Оплата і доставка</router-link></li>
            <li><router-link to="/privacy">Політика конфіденційності </router-link></li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="diwave"><a href="https://diwave.company/uk" target="_blank" rel="noopener">сайт створено в DIWAVE</a></div>
  </footer>
</template>

<script>
export default {
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
